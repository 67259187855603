import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CelulaComponent } from './celula.component';
import { CelulaEditComponent } from './celula.edit.component';
import { CelulasComponent } from './celulas.component';
import { CelulasRLComponent } from './telas-rl/celulas.rl.component';
import { CelulaManageComponent } from './tela-gerenciar/celula.manage.component';
import { GerenciamentoRLCelulaComponent } from './telas-rl/gerenciamento.rl.celula.component';
import { ParecerCelulaAutoridadeComponent } from './parecer/parecer.celula.autoridade.component';

const celulaRoutes: Routes = [
  {
    path: 'secured/celula/gerenciamento',
    component: GerenciamentoRLCelulaComponent,
  },
  {
    path: 'secured/celula',
    component: CelulaComponent,
  },
  {
    path: 'secured/celulas',
    component: CelulasComponent,
  },
  {
    path: 'secured/celula/:id',
    component: CelulaEditComponent,
  },
  // {
  //   path: 'secured/rl/celulas',
  //   component: CelulasRLComponent,
  // },
  {
    path: 'secured/celula/:id/gerenciar',
    component: CelulaManageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(celulaRoutes)],
  exports: [RouterModule],
})
export class CelulaRoutingModule {}
