import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeBr from '@angular/common/locales/br';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { LoginModule } from './login/login.module';
import { SecuredModule } from './secured/layout/secured.module';
import { UsuarioModule } from './secured/wop/usuario/usuario.module';
import { PermissaoModule } from './secured/wop/permissao/permissao.module';
import { AutorizacaoModule } from './secured/wop/autorizacao/autorizacao.module';
import { PaisModule } from './secured/wop/pais/pais.module';
import { EstadoModule } from './secured/wop/estado/estado.module';
import { CidadeModule } from './secured/wop/cidade/cidade.module';
import { LoaderComponent } from './core/loader/loader.component';
import { LoaderService } from './core/loader/service/loader.service';
import { LoaderInterceptor } from './core/loader/interceptors/loader.interceptor';
import { GrupoDominioModule } from './secured/wop/grupodominio/grupodominio.module';
import { RegionalModule } from './secured/wop/regional/regional.module';
import { MissaoModule } from './secured/wop/missao/missao.module';
import { CentroEvangelizacaoModule } from './secured/wop/centroevangelizacao/centroevangelizacao.module';
import { PessoaModule } from './secured/wop/pessoa/pessoa.module';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CelulaModule } from './secured/wop/celula/celula.module';
import { CasaComunitariaModule } from './secured/wop/casacomunitaria/casacomunitaria.module';
import { MinisterioModule } from './secured/wop/ministerio/ministerio.module';
import { ProjetoModule } from './secured/wop/projeto/projeto.module';
import { DifusaoModule } from './secured/wop/difusao/difusao.module';
import {
  DEFAULT_TIMEOUT,
  TimeoutInterceptor,
} from './core/interceptor/timeout.interceptor';
import { IrradiacaoModule } from './secured/wop/irradiacao/irradiacao.module';
import { AppRouteChangeService } from './core/services/app-route-change.service';
import { GrupoDominioLocalModule } from './secured/local/grupodominio/grupodominio.local.module';
import { PermissaoLocalModule } from './secured/local/permissao/permissao.local.module';
import { AutorizacaoLocalModule } from './secured/local/autorizacao/autorizacao.local.module';
import { PerfilModule } from './secured/wop/perfil/perfil.module';
import { RelacionamentosModule } from './secured/wop/relacionamentos/relacionamentos.module';
import { DadosVocacionaisModule } from './secured/wop/dadosvocacionais/dadosvocacionais.module';
import { CadastroPublicoModule } from './secured/local/cadastropublico/cadastro.publico.module';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ListagemPessoaModalModule } from './secured/wop/pessoa/modulo/listagem-pessoa-modal/listagempessoa.modal.module';
import { ListagemGrupoOracaoModalModule } from './secured/wop/grupodeoracao/listagem-grupo-modal/listagemgrupo.modal.module';
import { BemVindoModule } from './secured/local/bemvindo/bemvindo.module';
import { AcessoUsuarioEstruturaModule } from './secured/wop/acessousuarioestrutura/acessousuarioestrutura.module';
import { ConfirmacaoCadastroModule } from './secured/wop/confirmacaocadastro/confirmacao.cadastro.module';
import { UsuarioAssistenteApostolicoModule } from './secured/local/usuario/telas-assistente-apostolico/usuario.aa.module';
import { GrupoDeOracaoModule } from './secured/wop/grupodeoracao/grupodeoracao.module';
import { SaudeModule } from './secured/local/saude/saude.module';
import { MissionariosModule } from './secured/local/missionarios/missionarios.module';
import { WopGetAllService } from './service/getall.service';
import { RemanejamentoModule } from './secured/local/remanejamento/remanejamento.module';
import { AceiteTermoModule } from './secured/wop/aceitetermouso/aceitetermouso.module';
import { AceiteTermoTratamentoDadosModule } from './secured/wop/aceitetermotratamentodados/aceitetermotratamentodados.module';
import { EnvioLocalModule } from './secured/local/envio/envio.local.module';
import { CasaComunitariaLocalModule } from './secured/local/casacomunitaria/casacomunitaria.module';
import { QuestionarioModule } from './secured/local/questionario/questionario.module';
import { ParecerModule } from './secured/local/parecer/parecer.module';
import { MensagemRlModule } from './secured/local/mensagem-rl/mensagem-rl.module';
import { SearchCelulaAssistenteFormacaoModule } from './secured/local/assistente-formacao/assistente-formacao.module';
import { MissaoConfigModule } from './secured/local/missao-config-comp/missao-config.module';
import { LoggedInUserSharedDataService } from './secured/layout/header/service/logged-in-user-shared-data.service';
import { SearchCelulaRepsonsavelLocalModule } from './secured/local/celulas-dashboard-rl/celula-dashboard-rl.module';

registerLocaleData(localeBr);

@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    ChartsModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    LoginModule,
    SecuredModule,
    ParecerModule,
    UsuarioModule,
    PermissaoModule,
    PermissaoLocalModule,
    AutorizacaoModule,
    AutorizacaoLocalModule,
    PaisModule,
    EstadoModule,
    CidadeModule,
    GrupoDeOracaoModule,
    GrupoDominioModule,
    GrupoDominioLocalModule,
    RegionalModule,
    MissaoModule,
    CentroEvangelizacaoModule,
    PessoaModule,
    CelulaModule,
    CasaComunitariaModule,
    MinisterioModule,
    ProjetoModule,
    DifusaoModule,
    BrowserAnimationsModule,
    IgxTabsModule,
    ImageCropperModule,
    IrradiacaoModule,
    PerfilModule,
    RegionalModule,
    DadosVocacionaisModule,
    RelacionamentosModule,
    SaudeModule,
    MissionariosModule,
    RemanejamentoModule,
    CadastroPublicoModule,
    ConfirmacaoCadastroModule,
    NgxSummernoteModule,
    ListagemPessoaModalModule,
    ListagemGrupoOracaoModalModule,
    AcessoUsuarioEstruturaModule,
    UsuarioAssistenteApostolicoModule,
    BemVindoModule,
    AceiteTermoModule,
    AceiteTermoTratamentoDadosModule,
    EnvioLocalModule,
    CasaComunitariaLocalModule,
    QuestionarioModule,
    MensagemRlModule,
    SearchCelulaAssistenteFormacaoModule,
    SearchCelulaRepsonsavelLocalModule,
    MissaoConfigModule,
  ],
  providers: [
    LoaderService,
    WopGetAllService,
    AppRouteChangeService,
    LoggedInUserSharedDataService,
    [
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    ],
    { provide: DEFAULT_TIMEOUT, useValue: 1800000 },
    { provide: LOCALE_ID, useValue: 'br' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
